<template>
  <div>
    <div class="topButton">
      <CRow>
        <CCol sm="4">
          <div class="titleFont floatLeft">公司管理</div>
        </CCol>
        <CCol sm="8">
          <CButton
            variant="outline"
            class=" floatRight rbutton"
            color="primary"
            @click="createCompany()"
          >
            建立公司
          </CButton>
          <v-select
            class="floatRight selectCompany2 mtop20"
            v-model="selected1"
            :options="selectOptions"
            :filterable="true"
          >
          </v-select>
          <v-select
            class="floatRight selectCompany2 mtop20"
            v-model="selected"
            :options="selectOptions"
            :filterable="true"
          >
          </v-select>
        </CCol>
      </CRow>
    </div>
    <div class="clearBoth"></div>
    <CCard>
      <CCardBody>
        <div class="positionR">
          <div class="tableTitle"></div>
        </div>
        <CDataTable
          :items="company"
          :fields="fields"
          :sorter="{ external: true }"
          @update:sorter-value="handlerSorterValueChange"
          hover
          :noItemsView="{ noItems: '目前無公司資料' }"
        >
          <template #edit="{item, index}">
            <td class="py-2">
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="view(item, index)"
              >
                檢視
              </CButton>
              <CButton
                color="dark"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="edit(item, index)"
              >
                編輯
              </CButton>

              <CButton
                color="info"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="projectCodeList()"
              >
                專案代碼列表
              </CButton>
            </td>
          </template>
        </CDataTable>

        <div v-if="page != undefined">
          <CPagination
            :activePage.sync="page.current_page"
            :pages="page.last_page"
            align="end"
            @click.native="get(page.current_page)"
            v-if="page != ''"
          />
        </div>
      </CCardBody>
    </CCard>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'Clients',
  //   props: ['itemTable', 'fieldsTable'],
  data() {
    return {
      details: [],
      collapseDuration: 0,
      userAuth: sessionStorage.getItem('userAuth'),
      search: '',
      orderBy: '',
      sortedBy: '',
      selected: '搜尋公司名稱',
      selected1: '搜尋上層公司',
      company: [
        { id: 1, name: 'a', up: 'A' },
        { id: 2, name: 'b', up: 'B' },
        { id: 3, name: 'c', up: 'C' },
      ],
      page: { current_page: 1, last_page: 1 },
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      'get_companies',
      'get_companiesPage',
      'get_inStock',
      'get_companiesAll',
    ]),
    fields() {
      return [
        { key: 'name', label: '公司名稱', _style: 'min-width:200px' },
        { key: 'up', label: '上層公司', _style: 'min-width:200px' },

        {
          key: 'edit',
          label: '',
          _style: 'width:20%',
          sorter: false,
          filter: false,
        },
      ]
    },
    // 公司下拉
    selectOptions() {
      if (this.company) {
        return this.company.map((g) => ({
          label: g.name,
          id: g.id,
        }))
      }
    },
  },
  watch: {
    selectOptions() {
      $('.vs__search').attr('value', '請選擇')
    },
  },
  methods: {
    handlerSorterValueChange(o) {
      this.orderBy = o.column
      this.sortedBy = o.asc == true ? 'ASC' : 'DESC'
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    createCompany() {
      this.$router.push('/company/create')
    },
    view(item) {
      this.$router.push('/company/view/' + item.id)
    },
    edit(item) {
      this.$router.push('/company/edit/' + item.id)
    },
    projectCodeList() {
      this.$router.push('/projectCode')
    },
    get(page) {
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: page,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    Contract(item) {
      this.$router.push('/provider/company/contract/' + item.id)
    },
    getCompany() {
      let data = {
        companyName: this.search,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
  },
  created() {
    // this.orderBy = 'created_at'
    // this.sortedBy = 'DESC'
    // let data = {
    //   orderBy: this.orderBy,
    //   sortedBy: this.sortedBy,
    // }
    // this.$store.dispatch('actionCompanies', data)
    // let dataCompany = {
    //   orderBy: '',
    //   sortedBy: 'DESC',
    // }
    // this.$store.dispatch('actionCompaniesAll', dataCompany)
  },
}
</script>
